//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

.action-menu
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15)
  ul
    list-style-type: none
    margin: 0
    min-width: 240px
    border: 1px solid var(--borderColor-default)
    padding: 3px 0
    background: #ffffff

  li
    padding: 4px 13px 4px 10px
    &:hover
      background: #f0f0f0
      cursor: pointer
    &.has-no-icon
      padding: 4px 10px 4px 35px
    &.dropdown-divider
      border-top: 1px solid #eeeeee
      margin: 3px 0
      padding: 0
      font-size: 1px
    &:hover ul
      display: block
      margin: -28px 0 0 190px

    a
      color: var(--body-font-color)
      font-weight: normal
      &:hover
        text-decoration: none
    ul
      display: none
      position: absolute

.icon-action-menu
  @include icon-action-menu-rules
.icon-action-menu-post
  @include icon-action-menu-rules
  padding-left: 0.25rem
.icon-sub-menu
  @include icon-sub-menu-rules


.op-context-menu--overlay
  @include overlay-background
  display: none

  // Disable pointer events on this element
  // since it spans the entire body
  // and EDGE doesn't like it.
  pointer-events: none

  .dropdown
    pointer-events: initial

#work-package-context-menu, #column-context-menu
  &.action-menu
    position: absolute
    z-index: 1000
    // initial styles
    // are overridden immediately on first reposition
    // by contextMenuService
    top: 0
    left: 0
    visibility: hidden

#work-package-context-menu.action-menu
  position: fixed

.hascontextmenu
  cursor: context-menu

// Unless mobile
// hide the context menu until focused/hovered
html:not(.-browser-mobile)
  .context-menu--icon
    opacity: 0

    &:hover, &:focus
      opacity: 100

.context-menu--reveal
  &:hover, &:focus
    .context-menu--icon
      opacity: 100

