.op-long-text-attribute
  position: relative

  &--text
    overflow: hidden
    margin: 0
  &--text-hider
    position: absolute
    bottom: 0
    right: 0
    height: 1.5em
    width: 2em
  &--text-expander
    position: absolute
    bottom: 1px
    right: 0
    float: right
