//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

.my-page--container
  #right,
  #left
    flex: 0 0 50%
    max-width: 50%

  .widget-boxes
    margin: 0 -10px

    .widget-box
      margin-bottom: 20px
      overflow: auto

      &:last-child
        margin-bottom: 10px

@media screen and (max-width: $breakpoint-sm)
  .my-page--container .grid-block.widget-boxes
    flex-wrap: wrap

    #right,
    #left
      flex: 0 0 100%
      max-width: 100%

    #left
      margin-bottom: 0

div.box-actions
  float: right
  z-index: 500

#my_project_blocks .handle
  cursor: move

#visible-grid
  .handle
    cursor: move
  &.my-page--container .widget-boxes
    margin: 0

  #visible-grid &
    min-height: 32px
