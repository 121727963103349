.switch
	width: 68px
	height: 34px
	position: relative
	cursor: pointer
	input[type="checkbox"]
		width: 68px
		height: 34px
		cursor: pointer
		-webkit-appearance: none
		-moz-appearance: none
		appearance: none
		background: var(--button-default-bgColor-rest)
		border-radius: 4px
		border: 1px solid var(--button-default-borderColor-rest)
		position: relative
		outline: 0
		-webkit-transition: all .2s
		transition: all .2
		&:disabled
			opacity: 0.5
			cursor: not-allowed
	input[type="checkbox"]:after
		position: absolute
		content: ""
		top: 1px
		left: 1px
		width: 30px
		height: 30px
		background-color: #fff
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
		z-index: 2
		border-radius: 4px
		-webkit-transition: all .35s
		transition: all .35s
	svg
		position: absolute
		top: 50%
		-webkit-transform-origin: 50% 50%
		transform-origin: 50% 50%
		fill: #fff
		-webkit-transition: all .35s
		transition: all .35s
		z-index: 1
	.is_checked
		width: 28px
		left: 8%
		-webkit-transform: translateX(190%) translateY(-30%) scale(0)
		transform: translateX(190%) translateY(-30%) scale(0)
	.is_unchecked
		width: 28px
		right: -10%
		fill: var(--button--font-color)
		-webkit-transform: translateX(-40%) translateY(-50%) scale(1.5)
		transform: translateX(-40%) translateY(-50%) scale(1.5)
	input[type="checkbox"]:checked
		background-color: var(--button--primary-background-color)
		&:after
			left: calc(100% - 31px)
		& + .is_checked
			-webkit-transform: translateX(0) translateY(-50%) scale(1.5)
			transform: translateX(0) translateY(-50%) scale(1.5)
			&:disabled
				opacity: 0.5
				cursor: not-allowed
		& ~ .is_unchecked
			-webkit-transform: translateX(-100%) translateY(-30%) scale(0)
			transform: translateX(-100%) translateY(-30%) scale(0)
