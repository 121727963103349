@import 'helpers'

.op-meeting-agenda-item-form
  display: grid
  grid-template-columns: minmax(0, 100%) 100px minmax(200px, 1fr)
  grid-template-areas: "title duration presenter" "notes notes notes" "add_note actions actions"
  grid-gap: 8px
  // Do not remove easily. The hidden labels of the form inputs are positioned absolutely,
  // and thus breaking the whole layout if there is no container around them
  position: relative

  &--actions
    justify-self: end

  // Render an icon in front of the placeholder
  &--presenter
    .ng-placeholder
      @extend .icon-user
      &:before
        @include icon-font-common
        margin-right: 10px

  @media screen and (max-width: $breakpoint-md)
    grid-template-columns: 100px minmax(0, 100%)
    grid-template-areas: "title title" "duration presenter" "notes notes" "add_note actions"
