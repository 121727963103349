.ui-tooltip
  padding: 8px
  position: absolute
  z-index: 9999
  max-width: 300px
  font-size: 13.6px
  border-width: 2px

/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl
  border-top-left-radius: 3px

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr
  border-top-right-radius: 3px

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl
  border-bottom-left-radius: 3px

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br
  border-bottom-right-radius: 3px

.ui-tooltip.ui-widget-content
  border: 1px solid #c5c5c5
  background: var(--body-background)
  opacity: 1

.tooltip--map
  margin-top: 1em
  margin-bottom: 0
  list-style: none
  margin-left: 0
  font-size: 13.6px

  &:first-child
    margin-top: 0

.tooltip--map--key
  font-weight: var(--base-text-weight-bold)
