/*-- copyright
OpenProject Backlogs Plugin

Copyright (C) the OpenProject GmbH
Copyright (C)2011 Stephan Eckardt, Tim Felgentreff, Marnen Laibow-Koser, Sandro Munda
Copyright (C)2010-2011 friflaj
Copyright (C)2010 Maxime Guilbot, Andrew Vit, Joakim Kolsjö, ibussieres, Daniel Passos, Jason Vasquez, jpic, Emiliano Heyns
Copyright (C)2009-2010 Mark Maglana
Copyright (C)2009 Joe Heck, Nate Lowrie

This program is free software; you can redistribute it and/or modify it under
the terms of the GNU General Public License version 3.

OpenProject Backlogs is a derivative work based on ChiliProject Backlogs.
The copyright follows:
Copyright (C) 2010-2011 - Emiliano Heyns, Mark Maglana, friflaj
Copyright (C) 2011 - Jens Ulferts, Gregor Schmidt - Finn GmbH - Berlin, Germany

This program is free software; you can redistribute it and/or
modify it under the terms of the GNU General Public License
as published by the Free Software Foundation; either version 2
of the License, or (at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program; if not, write to the Free Software
Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.

See COPYRIGHT and LICENSE files for more details.

++*/

.jqplot-target {
  position: relative;
  color: #666;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.jqplot-axis {
  font-size: .75em;
}

.jqplot-xaxis {
  margin-top: 10px;
}

.jqplot-x2axis {
  margin-bottom: 10px;
}

.jqplot-yaxis {
  margin-right: 10px;
}

.jqplot-y2axis, .jqplot-y3axis, .jqplot-y4axis, .jqplot-y5axis, .jqplot-y6axis, .jqplot-y7axis, .jqplot-y8axis, .jqplot-y9axis {
  margin-left: 10px;
  margin-right: 10px;
}

.jqplot-axis-tick, .jqplot-xaxis-tick, .jqplot-yaxis-tick, .jqplot-x2axis-tick, .jqplot-y2axis-tick, .jqplot-y3axis-tick, .jqplot-y4axis-tick, .jqplot-y5axis-tick, .jqplot-y6axis-tick, .jqplot-y7axis-tick, .jqplot-y8axis-tick, .jqplot-y9axis-tick {
  position: absolute;
}

.jqplot-xaxis-tick {
  top: 0;
  left: 15px;
  vertical-align: top;
}

.jqplot-x2axis-tick {
  bottom: 0;
  left: 15px;
  vertical-align: bottom;
}

.jqplot-yaxis-tick {
  right: 0;
  top: 15px;
  text-align: right;
}

.jqplot-y2axis-tick, .jqplot-y3axis-tick, .jqplot-y4axis-tick, .jqplot-y5axis-tick, .jqplot-y6axis-tick, .jqplot-y7axis-tick, .jqplot-y8axis-tick, .jqplot-y9axis-tick {
  left: 0;
  top: 15px;
  text-align: left;
}

.jqplot-xaxis-label {
  margin-top: 10px;
  font-size: 11pt;
  position: absolute;
}

.jqplot-x2axis-label {
  margin-bottom: 10px;
  font-size: 11pt;
  position: absolute;
}

.jqplot-yaxis-label {
  margin-right: 10px;
  font-size: 11pt;
  position: absolute;
}

.jqplot-y2axis-label, .jqplot-y3axis-label, .jqplot-y4axis-label, .jqplot-y5axis-label, .jqplot-y6axis-label, .jqplot-y7axis-label, .jqplot-y8axis-label, .jqplot-y9axis-label {
  font-size: 11pt;
  position: absolute;
}

table.jqplot-table-legend, table.jqplot-cursor-legend {
  background-color: rgba(255, 255, 255, 0.6);
  border: 1px solid #ccc;
  position: absolute;
  font-size: .75em;
}

td.jqplot-table-legend {
  vertical-align: middle;
}

td.jqplot-table-legend > div {
  border: 1px solid #ccc;
  padding: .2em;
}

div.jqplot-table-legend-swatch {
  width: 0;
  height: 0;
  border-top-width: .35em;
  border-bottom-width: .35em;
  border-left-width: .6em;
  border-right-width: .6em;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
}

.jqplot-title {
  top: 0;
  left: 0;
  padding-bottom: .5em;
  font-size: 1.2em;
}

table.jqplot-cursor-tooltip {
  border: 1px solid #ccc;
  font-size: .75em;
}

.jqplot-cursor-tooltip {
  border: 1px solid #ccc;
  font-size: .75em;
  white-space: nowrap;
  background: rgba(208, 208, 208, 0.5);
  padding: 1px;
}

.jqplot-highlighter-tooltip {
  border: 1px solid #ccc;
  font-size: .75em;
  white-space: nowrap;
  background: rgba(208, 208, 208, 0.5);
  padding: 1px;
}

.jqplot-point-label {
  font-size: .75em;
}

td.jqplot-cursor-legend-swatch {
  vertical-align: middle;
  text-align: center;
}

div.jqplot-cursor-legend-swatch {
  width: 1.2em;
  height: .7em;
}
