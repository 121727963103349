/* -- copyright
// OpenProject My Project Page Plugin
//
// Copyright (C) the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.md for more details.

.op-app-header #nav-login-content,
#content,
.login-auth-providers a.auth-provider
  background-repeat: no-repeat
  background-size: 18px 18px
  background-position-x: 8px
  background-position-y: center
  background-color: var(--body-background)

.login-auth-providers a.auth-provider
  padding: 9px 12px 9px 12px

  &.auth-provider--imaged
    padding-left: 2rem

  &.auth-provider-google,
  &.auth-provider-azure,
  &.auth-provider-heroku
    padding-left: 34px

