.ck-powered-by
  display: none

.marker-green
  background-color: var(--ck-highlight-marker-green)

.marker-pink
  background-color: var(--ck-highlight-marker-pink)

.marker-yellow
  background-color: var(--ck-highlight-marker-yellow)

.marker-blue
  background-color: var(--ck-highlight-marker-blue)

.pen-red
  background-color: transparent
  color: var(--ck-highlight-pen-red)

.pen-green
  background-color: transparent
  color: var(--ck-highlight-pen-green)

.op-uc-container
  &_reduced-headings
    padding-bottom: unset

.wp-field-custom
  display: inline-flex
