.op-option-list
  display: flex
  flex-direction: column

  // TODO: remove the [type] selector once globally overwriting styles are removed
  &--input[type]
    margin: 0
    margin-right: 0.5rem

  &--item
    padding: 1rem 1rem 0.5rem 0.75rem
    display: flex
    border: 1px solid var(--borderColor-muted)
    background-color: var(--bgColor-muted)
    border-radius: 4px

    &:not(:last-child)
      margin-bottom: 0.5rem

    &_selected
      border: 1px solid var(--borderColor-accent-muted)
      background-color: var(--bgColor-accent-muted)

    &_disabled
      color: #959595

    &-title,
    &-description
      margin: 0
      margin-bottom: 0.5rem
      line-height: 1.2

    &-title
      font-weight: var(--base-text-weight-bold)

    &-description
      font-weight: normal
      font-size: 12px
