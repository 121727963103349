$color_1: #555;
$color_2: #e7e7e7;
$color_3: #000;
$color_4: #fff;
$font-family_1: "Bebas Neue";

.ck.ck-content {
	h3.category {
		font-family: $font-family_1;
		font-size: 20px;
		font-weight: bold;
		color: $color_1;
		letter-spacing: 10px;
		margin: 0;
		padding: 0;
	}
	h2.document-title {
		font-family: $font-family_1;
		font-size: 50px;
		font-weight: bold;
		margin: 0;
		padding: 0;
		border: 0;
	}
	h3.document-subtitle {
		font-family: $font-family_1;
		font-size: 20px;
		color: $color_1;
		margin: 0 0 1em;
		font-weight: normal;
		padding: 0;
	}
	p.info-box {
		--background-size: 30px;
		--background-color: #e91e63;
		padding: 1.2em 2em;
		border: 1.5px solid var(--background-color);
		background: #fff;
		border-radius: 10px;
		margin: 1.5em 2em;
		box-shadow: 5px 5px 0 #ffe6ef;
	}
	blockquote.side-quote {
		font-family: $font-family_1;
		font-style: normal;
		float: right;
		width: 35%;
		position: relative;
		border: 0;
		overflow: visible;
		z-index: 1;
		margin-left: 1em;
		&::before {
			content: "“";
			position: absolute;
			top: -37px;
			left: -10px;
			display: block;
			font-size: 200px;
			color: $color_2;
			z-index: -1;
			line-height: 1;
		}
		p {
			font-size: 2em;
			line-height: 1;
			&:last-child {
				&:not(:first-child) {
					font-size: 1.3em;
					text-align: right;
					color: $color_1;
				}
			}
		}
	}
	span.marker {
		background: yellow;
	}
	span.spoiler {
		background: #000;
		color: $color_3;
		&:hover {
			background: #000;
			color: $color_4;
		}
	}
}
