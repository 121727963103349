body.zen-mode
  #main-menu
    display: none
  #content-wrapper
    height: 100vh
    width: 100vw

  #main
    height: 100%
    grid-template-columns: auto
