/*-- copyright
OpenProject Backlogs Plugin

Copyright (C) the OpenProject GmbH
Copyright (C)2011 Stephan Eckardt, Tim Felgentreff, Marnen Laibow-Koser, Sandro Munda
Copyright (C)2010-2011 friflaj
Copyright (C)2010 Maxime Guilbot, Andrew Vit, Joakim Kolsjö, ibussieres, Daniel Passos, Jason Vasquez, jpic, Emiliano Heyns
Copyright (C)2009-2010 Mark Maglana
Copyright (C)2009 Joe Heck, Nate Lowrie

This program is free software; you can redistribute it and/or modify it under
the terms of the GNU General Public License version 3.

OpenProject Backlogs is a derivative work based on ChiliProject Backlogs.
The copyright follows:
Copyright (C) 2010-2011 - Emiliano Heyns, Mark Maglana, friflaj
Copyright (C) 2011 - Jens Ulferts, Gregor Schmidt - Finn GmbH - Berlin, Germany

This program is free software; you can redistribute it and/or
modify it under the terms of the GNU General Public License
as published by the Free Software Foundation; either version 2
of the License, or (at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU General Public License for more details.

You should have received a copy of the GNU General Public License
along with this program; if not, write to the Free Software
Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.

See COPYRIGHT and LICENSE files for more details.

++*/

#rb .meta {
  display:none;
}
#rb #helpers {
  display:none;
}
/*
  .editor is the classname for field editors of sprint,
  story, task, impediment. These field editors get created
  at runtime whenever any of the above models are edited.
*/
#rb .editors {
  display:none;
}
#rb .ui-dialog .editor {
  display:block;
}

/* dialog */
.ui-dialog .ui-dialog-title { float:left; margin-right:0; }
.ui-dialog.ui-widget-content { border:none; }
.ui-dialog .ui-dialog-buttonpane.ui-widget-content { border:none; }

.subject-input {
    width: 99%;
}

th {
  font-weight: var(--base-text-weight-bold);
}
