.wp-timeline
  &--grid-element
    position: absolute
    top: 0
    padding-top: 5px
    height: 100%
    border-right: 1px solid var(--borderColor-default)
    &_highlight
      border-right: 1px solid var(--borderColor-emphasis)

  &--non-working-day
    background-color: var(--bgColor-disabled)
