.op-select-footer
  display: block
  margin: 0
  padding: 0

  &--label
    cursor: pointer
    display: block
    background: transparent
    border: 0
    padding: 8px 10px
    line-height: 22px
    color: var(--body-font-color)
    font-weight: var(--base-text-weight-bold)
    width: 100%
    text-align: left

    &:hover
      color: var(--list-item-hover--color)
      background-color: var(--control-transparent-bgColor-hover)
