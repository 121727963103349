
// Additional styles for CodeMirror
// when editing code style snippets
.CodeMirror
  border: 1px solid #ddd
  color: var(--codeMirror-fgColor) !important
  background-color: var(--codeMirror-bgColor) !important

.CodeMirror-gutters
  background-color: var(--codeMirror-gutters-bgColor) !important

.CodeMirror-cursor
  border-left-color: var(--codeMirror-cursor-fgColor) !important
// ensure a toolbar on top of the code mirror area
// can still be clicked. The two interfere as both have position: relative
.CodeMirror-code
  pointer-events: none
