.work-packages--new
  .attributes-group.description-group
    // Remove bottom border
    .attributes-group--header
      border-bottom: none
      margin-bottom: 0

    // disable the resize grabber
    // as its resized automatically by content (height)
    textarea
      resize: none

    // As we currently do not have a fixed header in the creation page, we need to cancel out the padding of the page content.
    // Otherwise, the scrolled content will be visible above the toolbar
    // see: https://community.openproject.org/projects/openproject/work_packages/56921/activity
    .document-editor__toolbar
      top: -10px
