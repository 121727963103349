.op-uc-image
  width: auto
  height: auto
  max-width: 100%
  max-height: 100%
  border: 1px solid var(--secondary-color)

  // Needed to override built-in ckeditor-augmented-textarea styles
  @at-root .op-uc-container .image &
    margin: inherit   

.image-style-align-left
  float: left 
  margin-left: 1.5em
  margin-right: 2em !important

.image-style-align-right
  float: right 
  margin-left: 2em !important
  margin-right: 1.5em

.image-style-block-align-left
  margin-left: 0
  margin-right: auto

.image-style-block-align-right
  margin-left: auto
  margin-right: 0

.image-style-side 
  float: right 
  margin-left: 1.5em 
  max-width: 50% 
