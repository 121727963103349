
$project-status_not-set: var(--fgColor-subtle, var(--color-subtle-fg))
$project-status_off-track: var(--fgColor-danger, var(--color-danger-fg))
$project-status_at-risk: var(--fgColor-severe, var(--color-severe-fg))
$project-status_on-track: var(--fgColor-success, var(--color-success-fg))
$project-status_discontinued: var(--fgColor-attention, var(--color-attention-fg))
$project-status_not-started: var(--fgColor-accent, var(--fgColor-accent))
$project-status_finished: var(--fgColor-done, var(--color-done-fg))
$project-status-background-color: RGBA(var(--fgColor-subtle, var(--color-subtle-fg)), 0.3)

// Project status widget
.ng-select
  &.project-status
    min-width: 200px
    max-width: 250px
    .ng-select-container
      min-height: 56px !important
      height: 56px !important

    .ng-input
      height: 44px
      line-height: 44px
      input
        font-weight: var(--base-text-weight-bold)
        text-transform: uppercase

    .ng-clear-wrapper
      margin-left: 11px

.project-status--bulb
  display: inline-block
  width: 40px
  height: 40px
  border-radius: 50%
  border-width: 10px
  border-style: solid
  margin-right: 7px
  vertical-align: middle
  &.-not-set
    // Have to use uppercase functions to make sass ignore them :-/
    // https://github.com/sass/node-sass/issues/2251
    border-color: $project-status_not-set
    background-color: $project-status-background-color
  &.-off-track
    border-color: $project-status_off-track
    background-color: $project-status-background-color
  &.-at-risk
    border-color: $project-status_at-risk
    background-color: $project-status-background-color
  &.-on-track
    border-color: $project-status_on-track
    background-color: $project-status-background-color
  &.-not-started
    border-color: $project-status_not-started
    background-color: $project-status-background-color
  &.-finished
    border-color: $project-status_finished
    background-color: $project-status-background-color
  &.-discontinued
    border-color: $project-status_discontinued
    background-color: $project-status-background-color

  &.-inline
    height: 24px
    width: 24px
    border-width: 6px

.project-status--container
  display: inline-block

.project-status--display-field
  height: 56px
  .status.-editable
    line-height: 50px
    border-radius: 4px
  &:hover
    cursor: pointer

.project-status--name
  text-transform: uppercase
  font-weight: var(--base-text-weight-bold)

  &.-not-set
    color: $project-status_not-set
  &.-off-track
    color: $project-status_off-track
  &.-at-risk
    color: $project-status_at-risk
  &.-on-track
    color: $project-status_on-track
  &.-not-started
    color: $project-status_not-started
  &.-finished
    color: $project-status_finished
  &.-discontinued
    color: $project-status_discontinued

.project-status--pulldown-icon
  font-size: 10px
  vertical-align: middle
  &:before
    color: lightgrey

.project-status--explanation
  margin-top: 9px
